"use client";

import { useState, useTransition } from "react";
import { useTranslations } from "next-intl";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { If, Then, Else } from "react-if";
import { Loader2Icon } from "lucide-react";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";

import { IClaimUsernameFormProps, ClaimUsernameFormSchema } from "./lib/types";
import { claimUsernameFormAction } from "./lib/server-actions";
import { SuccessMessage } from "./success-message";

import { appConfig } from "@/config";

export function ClaimUsernameForm({
  title,
  description,
}: IClaimUsernameFormProps) {
  const t = useTranslations("forms.claimUsernameForm");
  const { toast } = useToast();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isPending, startTransition] = useTransition();
  const translatedZSchema = ClaimUsernameFormSchema();
  type Inputs = z.infer<typeof translatedZSchema>;

  const form = useForm<Inputs>({
    resolver: zodResolver(translatedZSchema),
    defaultValues: {
      username: "",
    },
  });

  const processForm: SubmitHandler<Inputs> = (data) => {
    startTransition(async () => {
      // Server action
      try {
        const { data: result, error } = await claimUsernameFormAction({
          data,
        });

        if (error) {
          toast({
            variant: "destructive",
            description: t("errorMessage"),
          });
        } else {
          setIsSubmitted(true);
        }
      } catch (error) {
        toast({
          variant: "destructive",
          description: t("errorMessage"),
        });
      }
    });
  };

  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-1 items-center justify-between gap-5 rounded-lg border bg-card p-6 lg:grid-cols-2">
        <div>
          <h2 className="text-2xl font-bold tracking-tight">
            {title ? title : t("title")}
          </h2>
          <p className="mt-1 text-muted-foreground">
            {description ? description : t("description")}
          </p>
        </div>

        <If condition={isSubmitted}>
          <Then>
            <SuccessMessage
              title={t("successTitle")}
              description={t("successMessage")}
            />
          </Then>
          <Else>
            <Form {...form}>
              <form
                className="flex w-full flex-row gap-3"
                onSubmit={form.handleSubmit(processForm)}
              >
                <div>
                  <Input
                    className="bg-primary/75 font-semibold text-primary-foreground !opacity-100"
                    value={`${appConfig.domain}/`}
                    disabled
                  />
                </div>

                <FormField
                  control={form.control}
                  name="username"
                  render={({ field }) => (
                    <FormItem className="flex-1">
                      <FormControl>
                        <Input
                          className="min-w-[150px] font-semibold"
                          placeholder="yourname"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Button type="submit" disabled={isPending}>
                  <If condition={isPending}>
                    <Then>
                      <Loader2Icon className="mr-2 h-4 w-4 animate-spin" />
                      {t("claiming")}
                    </Then>
                    <Else>{t("claim")}</Else>
                  </If>
                </Button>
              </form>
            </Form>
          </Else>
        </If>
      </div>
    </div>
  );
}
