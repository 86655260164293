import { useTranslations } from "next-intl";
import { z } from "zod";

export interface ISuccessMessageProps {
  title: string;
  description: string;
}

export interface IContactFormProps {
  title?: string;
  description?: string;
}

export function ContactFormSchema() {
  const t = useTranslations("forms.contactForm");

  return z.object({
    first_name: z.string().min(2, {
      message: t("invalidFirstName"),
    }),
    last_name: z.string().min(2, {
      message: t("invalidLastName"),
    }),
    email: z.string().email({ message: t("invalidEmail") }),
    message: z.string().min(10, {
      message: t("invalidMessage"),
    }),
  });
}

export interface IEmailNewsletterFormProps {
  title?: string;
  description?: string;
}

export function EmailNewsletterFormSchema() {
  const t = useTranslations("forms.emailNewsletterForm");

  return z.object({
    email: z.string().email({ message: t("invalidEmail") }),
  });
}

export interface IClaimUsernameFormProps {
  title?: string;
  description?: string;
}

export function ClaimUsernameFormSchema() {
  const t = useTranslations("forms.claimUsernameForm");

  return z.object({
    username: z.string().min(3, {
      message: t("invalidUsername"),
    }),
  });
}
