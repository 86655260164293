"use client";

import { motion } from "framer-motion";
import { useTranslations } from "next-intl";

export function HomeHero() {
  const t = useTranslations();

  return (
    <motion.div
      initial={{ opacity: 0.0, y: 40 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{
        delay: 0.1,
        duration: 0.8,
        ease: "easeInOut",
      }}
      className="container relative mx-auto flex flex-col items-center justify-center gap-y-5 py-10"
    >
      <h1 className="text-center text-5xl font-bold tracking-tight">
        {t("site.home.hero.title")}
      </h1>
      <p className="text-center text-lg">{t("site.home.hero.subtitle")}</p>
    </motion.div>
  );
}
