"use client";

import { PartyPopperIcon } from "lucide-react";

import { ISuccessMessageProps } from "./lib/types";

export function SuccessMessage({ title, description }: ISuccessMessageProps) {
  return (
    <div className="flex w-full flex-col items-center justify-center rounded-lg border bg-background p-6">
      <div>
        <PartyPopperIcon
          strokeWidth={1.5}
          className="mx-auto h-[36px] w-[36px]"
        />
      </div>
      <h3 className="mb-1 mt-3 text-center text-lg font-semibold tracking-tight">
        {title}
      </h3>
      <p className="text-center text-muted-foreground">{description}</p>
    </div>
  );
}
