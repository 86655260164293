"use client";

import { useState, useTransition } from "react";
import { useTranslations } from "next-intl";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { If, Then, Else } from "react-if";
import { Loader2Icon } from "lucide-react";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";

import { IContactFormProps, ContactFormSchema } from "./lib/types";
import { contactFormAction } from "./lib/server-actions";
import { SuccessMessage } from "./success-message";

export function ContactForm({ title, description }: IContactFormProps) {
  const t = useTranslations("forms.contactForm");
  const { toast } = useToast();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isPending, startTransition] = useTransition();
  const translatedZSchema = ContactFormSchema();
  type Inputs = z.infer<typeof translatedZSchema>;

  const form = useForm<Inputs>({
    resolver: zodResolver(translatedZSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      message: "",
    },
  });

  const processForm: SubmitHandler<Inputs> = (data) => {
    startTransition(async () => {
      // Server action
      try {
        const { data: result, error } = await contactFormAction({ data });

        if (error) {
          toast({
            variant: "destructive",
            description: t("errorMessage"),
          });
        } else {
          setIsSubmitted(true);
        }
      } catch (error) {
        toast({
          variant: "destructive",
          description: t("errorMessage"),
        });
      }
    });
  };

  return (
    <div className="container mx-auto">
      <div className="rounded-lg border bg-card p-6">
        <div className="mb-4">
          <h2 className="text-2xl font-bold tracking-tight">
            {title ? title : t("title")}
          </h2>
          <p className="mt-1 text-muted-foreground">
            {description ? description : t("description")}
          </p>
        </div>
        <If condition={isSubmitted}>
          <Then>
            <SuccessMessage
              title={t("successTitle")}
              description={t("successMessage")}
            />
          </Then>
          <Else>
            <Form {...form}>
              <form
                className="grid w-full grid-cols-1 gap-x-5 gap-y-2 lg:grid-cols-2"
                onSubmit={form.handleSubmit(processForm)}
              >
                <FormField
                  control={form.control}
                  name="first_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("firstName")}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="last_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("lastName")}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="lg:col-span-2">
                      <FormLabel>{t("emailAddress")}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="message"
                  render={({ field }) => (
                    <FormItem className="lg:col-span-2">
                      <FormLabel>{t("message")}</FormLabel>
                      <FormControl>
                        <Textarea className="resize-none" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Button
                  className="mt-2 lg:col-span-2"
                  type="submit"
                  disabled={isPending}
                >
                  <If condition={isPending}>
                    <Then>
                      <Loader2Icon className="mr-2 h-4 w-4 animate-spin" />
                      {t("sending")}
                    </Then>
                    <Else>{t("send")}</Else>
                  </If>
                </Button>
              </form>
            </Form>
          </Else>
        </If>
      </div>
    </div>
  );
}
